// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const jQuery = require("jquery");
// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
const Lazyload = require("vanilla-lazyload/dist/lazyload");

window.Lazyload = Lazyload;
require('jquery.easing');
require('waypoints/lib/jquery.waypoints');
require("bootstrap");
require("jquery-breakpoints");
require("bootstrap-notify");
require("magnific-popup");
//require("./plugins");

require("./functions");
require("./custom");
require("./libs/particles");
require("./libs/particles-dots");

// CSS
import 'scss/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
